import "../styles/index.scss"
import React from "react"
import Cart from "../components/Cart"
// import Header from '../components/header';
import Header from "../components/HeaderConfirm"

// console.log('ReactPixel | handleAddToCart', ReactPixel);
// import Context from '../context/StoreContext';

const CartPage = ({ location }) => {
  // const { store: { client, currentVariant, nextVariant, currentHandle, currentProduct } } = useContext(Context);
  // console.log('[currentVariant, client]', [ currentVariant, client ]);

  return (
    <React.Fragment>
      <Header path={location.pathname} />
      <Cart />
    </React.Fragment>
  )
}

export default CartPage
