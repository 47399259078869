import React, { useContext } from "react"
// import Img from 'gatsby-image';
import Context from "../context/StoreContext"

const LineItem = props => {
  const { line_item } = props
  const {
    updateLineItem,
    removeLineItem,
    store: { client, checkout },
  } = useContext(Context)
  // console.log('[client, checkout]', [ client, checkout ]);
  // console.log('line_item.variant.title', line_item.variant.title);

  // console.log('line_item', line_item);
  // console.log('checkout', checkout)

  const variantImage = line_item.variant.image ? (
    <img
      className="cart-item-image"
      src={line_item.variant.image.src}
      alt={`${line_item.title} product shot`}
    />
  ) : null

  const selectedOptions = line_item.variant.selectedOptions
    ? line_item.variant.selectedOptions.map(
        option => `${option.name}: ${option.value} `
      )
    : null

  const handleRemove = () => {
    // console.log('handleUpdateToVariant', client.checkout)
    removeLineItem(client, checkout.id, line_item.id)
  }

  const handleAddUpdateToVariant = () => {
    let qty = line_item.quantity
    qty += 1
    updateLineItem(client, checkout.id, line_item.id, qty)
  }
  const handleSubtractUpdateToVariant = () => {
    let qty = line_item.quantity
    qty -= 1
    updateLineItem(client, checkout.id, line_item.id, qty)
  }

  return (
    <div key={line_item.id} className="cart-item-container">
      <div className="cart-item-left-container">
        <div className="cart-item-left-inner-container">
          <div className="cart-item-image-wrapper">
            {/* <Img className="cart-item-image" fluid={imagePath} /> */}
            {/* <div className="cart-item-name">{cartItem.title}</div> */}
            {variantImage}
          </div>
          <div className="cart-item-name">{line_item.title}</div>
          {/* <div className="cart-item-name">{line_item.variant.title}</div> */}
        </div>

        <div className="cart-item-adjust-quantity-container">
          <span
            className="cart-item-adjust-quantity-button"
            onClick={handleSubtractUpdateToVariant}
          >
            -
          </span>{" "}
          {line_item.quantity}{" "}
          <span
            className="cart-item-adjust-quantity-button"
            onClick={handleAddUpdateToVariant}
          >
            +
          </span>
        </div>
      </div>

      <div className="cart-item-right-container">
        <div onClick={handleRemove} className="cart-item-delete">
          Delete
        </div>
        <div className="cart-item-price">${line_item.variant.price} usd</div>
      </div>
    </div>
  )
}

export default LineItem
