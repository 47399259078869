import React, { useState, useContext, useEffect } from "react"
import { Link } from "gatsby"
import Context from "../context/StoreContext"
import LineItem from "./CartLineItem"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import ReactPixel from "react-facebook-pixel"

// import fbTrack from '../lib/fbq';

const Cart = () => {
  const {
    store: { checkout },
  } = useContext(Context)
  const [tracking, setTracking] = useState(false)
  const [itemsSet, setItemsSet] = useState(false)
  const [items, setItems] = useState([])
  // const handleCheckout = () => {
  // 	window.open(checkout.webUrl);
  // };

  const line_items = checkout.lineItems.map(line_item => {
    // console.log("line_item.variant", line_item)
    if (!itemsSet) {
      setItems(prev =>
        prev.concat({
          title: line_item.title.toString(),
          price: Math.floor(line_item.quantity * 18),
          // paperType: [].concat(line_item.variant.map(v => v.type)),
          paperType: line_item.variant.title,
          quantity: line_item.quantity.toString(),
        })
      )
      setItemsSet(true)
    }
    return <LineItem key={line_item.id.toString()} line_item={line_item} />
  })

  // useEffect(() => {
  //   const line_items = checkout.lineItems.map(line_item => {
  //     return setItems(prev => prev.concat(line_item))
  //   })
  // }, [checkout?.lineItems])

  // console.log("items", items)

  const onCheckout = e => {
    // to stop the page reloading
    e.preventDefault()
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "Checkout Button",
      // string - required - Type of interaction (e.g. 'play')
      action: "Click",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: "Checkout Campaign",
      // number - optional - Numeric value associated with the event. (e.g. A product ID)
      value: 100,
    })

    /* 
    
    ReactPixel.track('Purchase', {
	[{'id': 'catBear_neo_lined', 'quantity': 2}, 
		{'id': adorableTreasure_bullet, 'quantity': 3}].
    num_items: 5,
    currency: 'USD',
    value: '90',
  });
    */
    if (!tracking) {
      // ReactPixel.track('track', 'Purchase', { value: 0.0, currency: 'GBP' });
      // ReactPixel.track("Purchase", { currency: "GBP" })
      ReactPixel.track("InitiateCheckout", items)
    }

    // fbTrack('track', 'Purchase', { value: 0.0, currency: 'GBP' });

    window.location = `${checkout.webUrl}`
  }

  return (
    <div className="cart-container">
      <div className="cart-title-your-cart-wrapper">
        <div className="cart-title-your-cart">Your Cart</div>
      </div>
      <div className="cart-items-container">
        {line_items}
        {line_items.length === 0 ? (
          <div className="cart-items-empty-heading">
            Your cart is empty ʕ•́ᴥ•̀ʔっ 💨
            <Link to="/journals">
              <span className="empty-cart-text"> head back</span>
            </Link>
          </div>
        ) : null}
        <div className="cart-subtotal-wrapper">
          <div className="cart-subtotal">
            <span style={{ paddingRight: "1rem" }}>Subtotal</span> $
            {checkout.subtotalPrice} usd
          </div>
        </div>

        <div className="cart-shipping-and-taxes-wrapper">
          <div className="cart-shipping-and-taxes">
            Shipping & taxes calculated at Checkout
          </div>
        </div>
      </div>
      {/* <div key={cartItem.sku} className="cart-item-container"> */}
      {/* <div className="checkout-button-wrapper">
				<a className="checkout-button" href={checkout.webUrl}>
					Checkout
				</a>
			</div> */}
      <div onClick={onCheckout} className="checkout-button-wrapper">
        <a className="checkout-button">Checkout</a>
      </div>
    </div>
  )
}

export default Cart
